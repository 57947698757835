import { Injectable } from "@angular/core";
import { DistinctivenessMapKey, MapSymbologyId } from "./maps.service";

type COLOR = `#${string}`;
type UKHabL2 = 'c' | 'w' | 'f' | 'g' | 'u' | 't' | 's' | 'r' | 'h';

interface UKHabSybmologyStripes {
  type: 'stripes';
  backgroundColor?: string;
  color: string;
  lineWidth: number;
  angle: number;
  dash: boolean;
}

interface UKHabSybmologyDots {
  type: 'dots';
  backgroundColor?: string;
  color: string;
}

interface UKHabSybmologyRhombs {
  type: 'rhombs';
  backgroundColor?: string;
  color: string;
  border: boolean;
}

type UKHabSybmology = UKHabSybmologyStripes | UKHabSybmologyDots | UKHabSybmologyRhombs | string;


const ukHabL2Colors: Record<UKHabL2, COLOR> = {
  c: '#FF7E00',
  w: '#339f2c',
  f: '#fd7bee',
  g: '#97ef5d',
  u: '#eb2244',
  t: '#1b53d6',
  s: '#a7a7a4',
  r: '#29ecf5',
  h: '#fafc81'
};

export const distinctivenessColors: Record<DistinctivenessMapKey, COLOR> = {
  0: '#FF595E',
  2: '#6A4C93',
  4: '#FFCA3A',
  6: '#1982C4',
  8: '#8AC927',
  10: '#BBBBBB'
};

const ukHabSybmology: [string, UKHabSybmology][] = [
  ['c1f', { type: 'rhombs', color: '#FFFFFF', backgroundColor: ukHabL2Colors['c'], border: false }],
  ['c1e', { type: 'rhombs', color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', border: false }],
  ['c1d', { type: 'dots', color: '#FFFFFF', backgroundColor: ukHabL2Colors['c'] }],
  ['c1c', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 0, dash: false }],
  ['c1b', { type: 'stripes', lineWidth: 1, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 90, dash: false }],
  ['c1a', { type: 'dots', color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF' }],
  ['c1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['c'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['c', ukHabL2Colors['c']],

  ['f2e', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['f'], backgroundColor: '#FAFC81', angle: 0, dash: false }],
  ['f2d', { type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['f'], angle: 90, dash: false }],
  ['f2a', { type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['f'], angle: 45, dash: false }],
  ['f2', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['f'], backgroundColor: '#FAFC81', angle: 135, dash: false }],
  ['f1b', { type: 'stripes', lineWidth: 1, color: ukHabL2Colors['f'], backgroundColor: '#FFFFFF', angle: 90, dash: false }],
  ['f1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['f'], angle: 45, dash: false }],
  ['f1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['f'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['f', ukHabL2Colors['f']],

  ['g4', '#CBFFB6'],
  ['g3c8', { type: 'dots', color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'] }],
  ['g3c7', { type: 'rhombs', color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', border: true }],
  ['g3c6', { type: 'rhombs', color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], border: false }],
  ['g3c5', { type: 'dots', color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6' }],
  ['g3c', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6', angle: 0, dash: false }],
  ['g3b', { type: 'stripes', lineWidth: 1, color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], angle: 90, dash: false }],
  ['g3a', { type: 'stripes', lineWidth: 4, color: '#CBFFB6', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false }],
  ['g3', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#CBFFB6', angle: 135, dash: false }],
  ['g2b', { type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], angle: 90, dash: false }],
  ['g2a', { type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false }],
  ['g2', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#FAFC81', angle: 135, dash: false }],
  ['g1c', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 0, dash: false }],
  ['g1b6', { type: 'rhombs', color: '#FAFC81', backgroundColor: ukHabL2Colors['g'], border: false }],
  ['g1b5', { type: 'dots', color: ukHabL2Colors['g'], backgroundColor: '#FAFC81' }],
  ['g1b', { type: 'stripes', lineWidth: 1, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 90, dash: false }],
  ['g1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['g'], angle: 45, dash: false }],
  ['g1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['g'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['g', ukHabL2Colors['g']],

  ['h3', { type: 'stripes', lineWidth: 4, color: '#EB2244', backgroundColor: '#8268D6', angle: 135, dash: false }],
  ['h2b', { type: 'stripes', lineWidth: 2, color: '#EB2244', backgroundColor: '#8268D6', angle: 0, dash: true }],
  ['h2a', { type: 'stripes', lineWidth: 2, color: '#000000', backgroundColor: '#8268D6', angle: 0, dash: true }],
  ['h2', { type: 'stripes', lineWidth: 2, color: '#FAFC81', backgroundColor: '#8268D6', angle: 0, dash: true }],
  ['h1b6', { type: 'rhombs', color: '#FFFFFF', backgroundColor: '#8268D6', border: false }],
  ['h1b5', { type: 'dots', color: '#8268D6', backgroundColor: '#FFFFFF' }],
  ['h1b', { type: 'stripes', lineWidth: 1, color: '#8268D6', backgroundColor: '#FFFFFF', angle: 90, dash: false }],
  ['h1a7', { type: 'rhombs', color: '#8268D6', backgroundColor: '#FFFFFF', border: false }],
  ['h1a5', { type: 'dots', color: '#FFFFFF', backgroundColor: '#8268D6' }],
  ['h1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: '#8268D6', angle: 45, dash: false }],
  ['h1', { type: 'stripes', lineWidth: 4, color: '#8268D6', backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['h', ukHabL2Colors['h']],

  ['r2', { type: 'dots', color: '#FAFC81', backgroundColor: ukHabL2Colors['r'] }],
  ['r1e', { type: 'dots', color: ukHabL2Colors['r'], backgroundColor: '#FFFFFF' }],
  ['r1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['r'], angle: 45, dash: false }],
  ['r1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['r'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['r', ukHabL2Colors['r']],

  ['s3b', { type: 'stripes', lineWidth: 1, color: '#CFCFCA', backgroundColor: ukHabL2Colors['s'], angle: 90, dash: false }],
  ['s3a', { type: 'stripes', lineWidth: 4, color: '#CFCFCA', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false }],
  ['s3', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#CFCFCA', angle: 135, dash: false }],
  ['s2a', { type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false }],
  ['s2', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#FAFC81', angle: 135, dash: false }],
  ['s1d', { type: 'dots', color: ukHabL2Colors['s'], backgroundColor: '#FFFFFF' }],
  ['s1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['s'], angle: 45, dash: false }],
  ['s1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['s'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['s', ukHabL2Colors['s']],

  ['t2d', { type: 'dots', color: ukHabL2Colors['t'], backgroundColor: '#FAFC81' }],
  ['t2a', { type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['t'], angle: 45, dash: false }],
  ['t2', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['t'], backgroundColor: '#FAFC81', angle: 135, dash: false }],
  ['t1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['t'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['t', ukHabL2Colors['t']],

  ['u1e', '#F36F85'],
  ['u1d', { type: 'rhombs', color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF', border: false }],
  ['u1c', { type: 'dots', color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'] }],
  ['u1b6', { type: 'rhombs', color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], border: false }],
  ['u1b5', { type: 'dots', color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF' }],
  ['u1b', { type: 'stripes', lineWidth: 1, color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], angle: 90, dash: false }],
  ['u1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['u'], angle: 45, dash: false }],
  ['u1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['u'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['u', ukHabL2Colors['u']],

  ['w2c', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#FAFC81', angle: 0, dash: false }],
  ['w2b', { type: 'stripes', lineWidth: 1, color: '#FAFC81', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false }],
  ['w2a', { type: 'stripes', lineWidth: 4, color: '#FAFC81', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false }],
  ['w2', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['w'], backgroundColor: '#FAFC81', angle: 135, dash: false }],
  ['w1h', { type: 'dots', color: ukHabL2Colors['w'], backgroundColor: '#A94900' }],
  ['w1g6', { type: 'stripes', lineWidth: 2, color: '#000000', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: true }],
  ['w1g', { type: 'dots', color: '#A94900', backgroundColor: ukHabL2Colors['w'] }],
  ['w1f', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#A94900', angle: 0, dash: false }],
  ['w1e', { type: 'stripes', lineWidth: 1, color: '#A94900', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false }],
  ['w1d', { type: 'stripes', lineWidth: 4, color: '#A94900', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false }],
  ['w1c', { type: 'stripes', lineWidth: 2, color: ukHabL2Colors['w'], backgroundColor: '#FFFFFF', angle: 0, dash: false }],
  ['w1b', { type: 'stripes', lineWidth: 1, color: '#FFFFFF', backgroundColor: ukHabL2Colors['w'], angle: 90, dash: false }],
  ['w1a', { type: 'stripes', lineWidth: 4, color: '#FFFFFF', backgroundColor: ukHabL2Colors['w'], angle: 45, dash: false }],
  ['w1', { type: 'stripes', lineWidth: 4, color: ukHabL2Colors['w'], backgroundColor: '#FFFFFF', angle: 135, dash: false }],
  ['w', ukHabL2Colors['w']],
];

const distinctivenessSymbology: [DistinctivenessMapKey, COLOR][] = [
  [0, distinctivenessColors[0]],
  [2, distinctivenessColors[2]],
  [4, distinctivenessColors[4]],
  [6, distinctivenessColors[6]],
  [8, distinctivenessColors[8]],
  [10, distinctivenessColors[10]]
];

export const symbologyColors: mapboxgl.Expression = [
  'match',
  ['get', 'l2'],
  'c',
  ukHabL2Colors['c'],
  'w',
  ukHabL2Colors['w'],
  'f',
  ukHabL2Colors['f'],
  'g',
  ukHabL2Colors['g'],
  'u',
  ukHabL2Colors['u'],
  't',
  ukHabL2Colors['t'],
  's',
  ukHabL2Colors['s'],
  'r',
  ukHabL2Colors['r'],
  'h',
  ukHabL2Colors['h'],
  '#000000'
];

const lineColorMap: [string, string, string][] = [
  ['f2d', '#fd7bee', '#f6f176'],

  ['g3c', '#97ef5d', '#cbfeb5'],
  ['g3c5', '#97ef5d', '#cbfeb5'],
  ['g3c6', '#97ef5d', '#cbfeb5'],
  ['g3c7', '#97ef5d', '#cbfeb5'],
  ['g3c8', '#97ef5d', '#cbfeb5'],

  ['h2', '#8167d6', '#f6f176'],

  ['h2a', '#8167d6', '#000000'],
  ['h2a5', '#8167d6', '#000000'],
  ['h2a6', '#8167d6', '#000000'],

  ['h2b', '#8167d6', '#ec2243'],

  ['r1e', '#29ecf5', '#f6f176'],
  ['u1e', '#ec2243', '#ec2243'],
  ['w1g', '#339f2c', '#A94900'],
  ['w1g6', '#339f2c', '#000000'],
];

export const symbologyTreeNodes = [
  {
    key: 'c',
    label: 'Cropland (c)',
    data: { level: 2 },
    children: [
      {
        key: 'c1',
        label: 'Arable and horticulture (c1)',
        data: { level: 3 },
        children: [
          {
            key: 'c1a',
            label: 'Arable field margins (c1a)',
            data: { level: 4 },
            children: [
              {
                key: 'c1a5',
                label: 'Arable field margins – tussocky (c1a5)',
                data: { level: 5 }
              },
              {
                key: 'c1a6',
                label: 'Arable field margins – pollen and nectar (c1a6)',
                data: { level: 5 }
              },
              {
                key: 'c1a7',
                label: 'Arable field margins – cultivated annually (c1a7)',
                data: { level: 5 }
              },
              {
                key: 'c1a8',
                label: 'Arable field margins – wild bird mix (c1a8)',
                data: { level: 5 }
              },
            ]
          },
          {
            key: 'c1b',
            label: 'Temporary grass and clover leys (c1b)',
            data: { level: 4 },
            children: [
              {
                key: 'c1b5',
                label: 'Rye-grass and clover ley (c1b5)',
                data: { level: 5 }
              },
              {
                key: 'c1b6',
                label: 'Legume-rich ley (c1b6)',
                data: { level: 5 }
              },
              {
                key: 'c1b7',
                label: 'Herb-rich ley (c1b7)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'c1c',
            label: 'Cereal crops (c1c)',
            data: { level: 4 },
            children: [
              {
                key: 'c1c5',
                label: 'Winter stubble (c1c5)',
                data: { level: 5 }
              },
              {
                key: 'c1c6',
                label: 'Arable fields – wild bird mix (c1c6)',
                data: { level: 5 }
              },
              {
                key: 'c1c7',
                label: 'Other cereal crops (c1c7)',
                data: { level: 5 }
              },
              {
                key: 'c1c8',
                label: 'Arable fields – pollen and nectar (c1c8)',
                data: { level: 5 }
              },
              {
                key: 'c1c9',
                label: 'Arable fields – cultivated for annual flora (c1c9)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'c1d',
            label: 'Non-cereal crops (c1d)',
            data: { level: 4 },
            children: [
              {
                key: 'c1d5',
                label: 'Miscanthus (c1d5)',
                data: { level: 5 }
              },
              {
                key: 'c1d6',
                label: 'Short-rotation copppice (c1d6)',
                data: { level: 5 }
              },
              {
                key: 'c1d7',
                label: 'Vineyards (c1d7)',
                data: { level: 5 }
              },
              {
                key: 'c1d8',
                label: 'Other non-cereal crops (c1d8)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'c1e',
            label: 'Intensive orchards (c1e)',
            data: { level: 4 }
          },
          {
            key: 'c1f',
            label: 'Horticulture (c1f)',
            data: { level: 4 },
            children: [
              {
                key: 'c1f5',
                label: 'Annuals horticulture (c1f5)',
                data: { level: 5 }
              },
              {
                key: 'c1f6',
                label: 'Perennials horticulture (c1f6)',
                data: { level: 5 }
              },
              {
                key: 'c1f7',
                label: 'Polyculture (c1f7)',
                data: { level: 5 }
              },
            ]
          },
        ]
      },
    ]
  },
  {
    key: 'f',
    label: 'Wetland (f)',
    data: { level: 2 },
    children: [
      {
        key: 'f1',
        label: 'Bog (f1)',
        data: { level: 3 },
        children: [
          {
            key: 'f1a',
            label: 'Blanket bog (f1a)',
            data: { level: 4 },
            children: [
              {
                key: 'f1a5',
                label: 'Blanket bog (H7130) (f1a5)',
                data: { level: 5 }
              },
              {
                key: 'f1a6',
                label: 'Degraded blanket bog (f1a6)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'f1b',
            label: 'Lowland raised bog (f1b)',
            data: { level: 4 },
            children: [
              {
                key: 'f1b5',
                label: 'Active raised bogs (H7110) (f1b5)',
                data: { level: 5 }
              },
              {
                key: 'f1b6',
                label: 'Degraded raised bog (H7120) (f1b6)',
                data: { level: 5 }
              },
              {
                key: 'f1b7',
                label: 'Other degraded raised bog (f1b7)',
                data: { level: 5 }
              },
            ]
          },
        ]
      },
      {
        key: 'f2',
        label: 'Fen marsh and swamp (f2)',
        data: { level: 3 },
        children: [
          {
            key: 'f2a',
            label: 'Lowland fens (f2a)',
            data: { level: 4 },
            children: [
              {
                key: 'f2a5',
                label: 'Calcium-rich fen dominated by great fen-sedge (H7210) (f2a5)',
                data: { level: 5 }
              },
              {
                key: 'f2a6',
                label: 'Hard-water springs depositing lime – lowland (H7220) (f2a6)',
                data: { level: 5 }
              },
              {
                key: 'f2a7',
                label: 'Calcium-rich springwater-fed fens – lowland (H7230) (f2a7)',
                data: { level: 5 }
              },
              {
                key: 'f2a8',
                label: 'Transition mires and quaking bogs – lowland (H7140)g (f2a8)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'f2b',
            label: 'Purple moor-grass and rush pastures (f2b)',
            data: { level: 4 },
            children: [
              {
                key: 'f2b5',
                label: 'Purple moor-grass meadows (H6410) (f2b5)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'f2c',
            label: 'Upland flushes fens and swamps (f2c)',
            data: { level: 4 },
            children: [
              {
                key: 'f2c5',
                label: 'Alpine pioneer formations (H7240) (f2c5)',
                data: { level: 5 }
              },
              {
                key: 'f2c6',
                label: 'Hard-water springs depositing lime – upland (H7220) (f2c6)',
                data: { level: 5 }
              },
              {
                key: 'f2c7',
                label: 'Calcium-rich springwater-fed fens – upland (H7230) (f2c7)',
                data: { level: 5 }
              },
              {
                key: 'f2c8',
                label: 'Transition mires and quaking bogs – upland (H7140) (f2c8)',
                data: { level: 5 }
              }
            ]
          },
          {
            key: 'f2d',
            label: 'Aquatic marginal vegetation (f2d)',
            data: { level: 4 }
          },
          {
            key: 'f2e',
            label: 'Reedbeds (f2e)',
            data: { level: 4 }
          },
          {
            key: 'f2f',
            label: 'Other wetlands (f2f)',
            data: { level: 4 }
          }
        ]
      },
    ]
  },
  {
    key: 'g',
    label: 'Grassland (g)',
    data: { level: 2 },
    children: [
      {
        key: "g1",
        label: "Acid grassland (g1)",
        level: "3",
        children: [
          {
            key: "g1a",
            label: "Lowland dry acid grassland (g1a)",
            level: "4",
            children: [
              {
                key: "g1a5",
                label: "Inland dunes with open grasslands (H2330) (g1a5)",
                level: "5"
              },
              {
                key: "g1a6",
                label: "Other lowland dry acid grassland (g1a6)",
                level: "5"
              }
            ]
          },
          {
            key: "g1b",
            label: "Upland acid grassland (g1b)",
            level: "4",
            children: [
              {
                key: "g1b5",
                label: "Montane acid grasslands (H6150) (g1b5)",
                level: "5"
              },
              {
                key: "g1b6",
                label: "Other upland acid grassland (g1b6)",
                level: "5"
              }
            ]
          },
          {
            key: "g1c",
            label: "Bracken (g1c)",
            level: "4"
          },
          {
            key: "g1d",
            label: "Other lowland acid grassland (g1d)",
            level: "4"
          }
        ]
      },
      {
        key: "g2",
        label: "Calcareous grassland (g2)",
        level: "3",
        children: [
          {
            key: "g2a",
            label: "Lowland calcareous grassland (g2a)",
            level: "4",
            children: [
              {
                key: "g2a5",
                label: "Dry grasslands and scrub on chalk or limestone – lowland (H6210) (g2a5)",
                level: "5"
              },
              {
                key: "g2a6",
                label: "Dry grasslands and scrub on chalk or limestone – important orchid sites (H6210) (g2a6)",
                level: "5"
              }
            ]
          },
          {
            key: "g2b",
            label: "Upland calcareous grassland (g2b)",
            level: "4",
            children: [
              {
                key: "g2b5",
                label: "Alpine and subalpine calcareous grasslands (H6170) (g2b5)",
                level: "5"
              },
              {
                key: "g2b6",
                label: "Species-rich grassland with mat-grass in upland areas (H6230) (g2b6)",
                level: "5"
              },
              {
                key: "g2b7",
                label: "Dry grasslands and scrub on chalk or limestone – upland (H6210) (g2b7)",
                level: "5"
              }
            ]
          },
          {
            key: "g2c",
            label: "Other calcareous grassland (g2c)",
            level: "4"
          }
        ]
      },
      {
        key: "g3",
        label: "Neutral grassland (g3)",
        level: "3",
        children: [
          {
            key: "g3a",
            label: "Lowland meadows (g3a)",
            level: "4",
            children: [
              {
                key: "g3a5",
                label: "Lowland hay meadows (H6510) (g3a5)",
                level: "5"
              },
              {
                key: "g3a6",
                label: "Other lowland meadows (g3a6)",
                level: "5"
              }
            ]
          },
          {
            key: "g3b",
            label: "Upland hay meadows (g3b)",
            level: "4",
            children: [
              {
                key: "g3b5",
                label: "Mountain hay meadows (H6520) (g3b5)",
                level: "5"
              }
            ]
          },
          {
            key: "g3c",
            label: "Other neutral grassland (g3c)",
            level: "4",
            children: [
              {
                key: "g3c5",
                label: "Arrhenatherum neutral grassland (g3c5)",
                level: "5"
              },
              {
                key: "g3c6",
                label: "Lolium-Cynosurus neutral grassland (g3c6)",
                level: "5"
              },
              {
                key: "g3c7",
                label: "Deschampsia neutral grassland (g3c7)",
                level: "5"
              },
              {
                key: "g3c8",
                label: "Holcus-Juncus neutral grassland (g3c8)",
                level: "5"
              }
            ]
          }
        ]
      },
      {
        key: "g4",
        label: "Modified grassland (g4)",
        level: "3"
      }
    ]
  },
  {
    key: "h",
    label: "Heathland and shrub (h)",
    data: { level: 2 },
    children: [
      {
        key: "h1",
        label: "Dwarf shrub heath (h1)",
        data: { level: 3 },
        children: [
          {
            key: "h1a",
            label: "Lowland Heathland (h1a)",
            data: { level: 4 },
            children: [
              {
                key: "h1a5",
                label: "Dry heaths – lowland (H4030) (h1a5)",
                data: { level: 5 }
              },
              {
                key: "h1a6",
                label: "Dry coastal heaths with Cornish heath (H4040) (h1a6)",
                data: { level: 5 }
              },
              {
                key: "h1a7",
                label: "Wet heathland with cross-leaved heath – lowland (H4010) (h1a7)",
                data: { level: 5 }
              },
              {
                key: "h1a8",
                label: "Wet heathland with Dorset heath and cross-leaved heath (H4020) (h1a8)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h1b",
            label: "Upland Heathland (h1b)",
            data: { level: 4 },
            children: [
              {
                key: "h1b5",
                label: "Dry heaths – upland (H4030) (h1b5)",
                data: { level: 5 }
              },
              {
                key: "h1b6",
                label: "Wet heathland with cross-leaved heath – upland (H4010) (h1b6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h1c",
            label: "Mountain heaths and willow scrub (h1c)",
            data: { level: 4 },
            children: [
              {
                key: "h1c5",
                label: "Alpine and subalpine heaths (H4060) (h1c5)",
                data: { level: 5 }
              },
              {
                key: "h1c6",
                label: "Mountain willow scrub (H4080) (h1c6)",
                data: { level: 5 }
              }
            ]
          }
        ]
      },
      {
        key: "h2",
        label: "Hedgerows (h2)",
        data: { level: 3 },
        children: [
          {
            key: "h2a",
            label: "Native hedgerow (h2a)",
            data: { level: 4 },
            children: [
              {
                key: "h2a5",
                label: "Species-rich native hedgerow (h2a5)",
                data: { level: 5 }
              },
              {
                key: "h2a6",
                label: "Other native hedgerow (h2a6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h2b",
            label: "Non-native and ornamental hedgerow (h2b)",
            data: { level: 4 }
          }
        ]
      },
      {
        key: "h3",
        label: "Dense scrub (h3)",
        data: { level: 3 },
        children: [
          {
            key: "h3a",
            label: "Blackthorn scrub (h3a)",
            data: { level: 4 },
            children: [
              {
                key: "h3a5",
                label: "West coast blackthorn scrub (h3a5)",
                data: { level: 5 }
              },
              {
                key: "h3a6",
                label: "Other blackthorn scrub (h3a6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h3b",
            label: "Hazel scrub (h3b)",
            data: { level: 4 },
            children: [
              {
                key: "h3b5",
                label: "Atlantic hazel (h3b5)",
                data: { level: 5 }
              },
              {
                key: "h3b6",
                label: "Other hazel scrub (h3b6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h3c",
            label: "Sea-buckthorn scrub (h3c)",
            data: { level: 4 },
            children: [
              {
                key: "h3c5",
                label: "Dunes with sea-buckthorn (H2160) (h3c5)",
                data: { level: 5 }
              },
              {
                key: "h3c6",
                label: "Other sea-buckthorn scrub (h3c6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "h3d",
            label: "Bramble scrub (h3d)",
            data: { level: 4 }
          },
          {
            key: "h3e",
            label: "Gorse scrub (h3e)",
            data: { level: 4 }
          },
          {
            key: "h3f",
            label: "Hawthorn scrub (h3f)",
            data: { level: 4 }
          },
          {
            key: "h3g",
            label: "Rhododendron scrub (h3g)",
            data: { level: 4 }
          },
          {
            key: "h3h",
            label: "Mixed scrub (h3h)",
            data: { level: 4 }
          },
          {
            key: "h3j",
            label: "Willow scrub (h3j)",
            data: { level: 4 }
          },
          {
            key: "h3k",
            label: "Juniper scrub (h3k)",
            data: { level: 4 }
          }
        ]
      }
    ]
  },
  {
    key: "r",
    label: "Rivers and lakes (r)",
    data: { level: 2 },
    children: [
      {
        key: "r1",
        label: "Standing open water and canals (r1)",
        data: { level: 3 },
        children: [
          {
            key: "r1a",
            label: "Eutrophic standing waters (r1a)",
            data: { level: 4 },
            children: [
              {
                key: "r1a5",
                label: "Naturally nutrient-rich lakes or lochs (H3150) (r1a5)",
                data: { level: 5 }
              },
              {
                key: "r1a6",
                label: "Other eutrophic standing waters (r1a6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r1b",
            label: "Mesotrophic lakes (r1b)",
            data: { level: 4 },
            children: [
              {
                key: "r1b5",
                label: "Calcium-rich nutrient-poor lakes lochs and pools (H3140) (r1b5)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r1c",
            label: "Oligotrophic and dystrophic lakes (r1c)",
            data: { level: 4 },
            children: [
              {
                key: "r1c5",
                label: "Clear-water lakes or lochs with aquatic vegetation (H3130) (r1c5)",
                data: { level: 5 }
              },
              {
                key: "r1c6",
                label: "Nutrient-poor shallow waters with aquatic vegetation on sand (H3110) (r1c6)",
                data: { level: 5 }
              },
              {
                key: "r1c7",
                label: "Acid peat-stained lakes and ponds (H3160) (r1c7)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r1d",
            label: "Aquifer-fed naturally fluctuating water bodies (r1d)",
            data: { level: 4 },
            children: [
              {
                key: "r1d5",
                label: "Turlough (H3180) (r1d5)",
                data: { level: 5 }
              },
              {
                key: "r1d6",
                label: "Fluctuating meres (r1d6)",
                data: { level: 5 }
              },
              {
                key: "r1d7",
                label: "Blow wells (r1d7)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r1e",
            label: "Canals (r1e)",
            data: { level: 4 }
          },
          {
            key: "r1f",
            label: "Temporary water bodies (r1f)",
            data: { level: 4 },
            children: [
              {
                key: "r1f5",
                label: "Mediterranean temporary ponds (H3170) (r1f5)",
                data: { level: 5 }
              },
              {
                key: "r1f6",
                label: "Other temporary ponds and scrapes (r1f6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r1g",
            label: "Other standing water (r1g)",
            data: { level: 4 }
          }
        ]
      },
      {
        key: "r2",
        label: "Rivers and streams (r2)",
        data: { level: 3 },
        children: [
          {
            key: "r2a",
            label: "Rivers (priority habitat) (r2a)",
            data: { level: 4 },
            children: [
              {
                key: "r2a5",
                label: "Rivers with floating vegetation (H3260) (r2a5)",
                data: { level: 5 }
              },
              {
                key: "r2a6",
                label: "Other priority habitat rivers (r2a6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "r2b",
            label: "Other rivers and streams (r2b)",
            data: { level: 4 }
          }
        ]
      }
    ]
  },
  {
    key: "s",
    label: "Sparsely vegetated land (s)",
    data: { level: 2 },
    children: [
      {
        key: "s1",
        label: "Inland rock (s1)",
        data: { level: 3 },
        children: [
          {
            key: "s1a",
            label: "Inland rock outcrop and scree habitats (s1a)",
            data: { level: 4 },
            children: [
              {
                key: "s1a5",
                label: "Acidic scree (H8110) (s1a5)",
                data: { level: 5 }
              },
              {
                key: "s1a6",
                label: "Base-rich scree (H8120) (s1a6)",
                data: { level: 5 }
              },
              {
                key: "s1a7",
                label: "Plants in crevices in base-rich rocks (H8210) (s1a7)",
                data: { level: 5 }
              },
              {
                key: "s1a8",
                label: "Plants in crevices in acid rocks (H8220) (s1a8)",
                data: { level: 5 }
              },
              {
                key: "s1a9",
                label: "Tall herb communities (H6430) (s1a9)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "s1b",
            label: "Limestone pavement (s1b)",
            data: { level: 4 },
            children: [
              {
                key: "s1b5",
                label: "Limestone pavements (H8240) (s1b5)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "s1c",
            label: "Calaminarian grasslands (s1c)",
            data: { level: 4 },
            children: [
              {
                key: "s1c5",
                label: "Grasslands on soils rich in heavy metals (H6130) (s1c5)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "s1d",
            label: "Other inland rock and scree (s1d)",
            data: { level: 4 }
          }
        ]
      },
      {
        key: "s2",
        label: "Supralittoral Rock (s2)",
        data: { level: 3 },
        children: [
          {
            key: "s2a",
            label: "Maritime cliffs and slopes (s2a)",
            data: { level: 4 },
            children: [
              {
                key: "s2a5",
                label: "Vegetated sea cliffs (H1230) (s2a5)",
                data: { level: 5 }
              },
              {
                key: "s2a6",
                label: "Soft rock sea cliffs (s2a6)",
                data: { level: 5 }
              }
            ]
          }
        ]
      },
      {
        key: "s3",
        label: "Supralittoral Sediment (s3)",
        data: { level: 3 },
        children: [
          {
            key: "s3a",
            label: "Sand dunes (s3a)",
            data: { level: 4 },
            children: [
              {
                key: "s3a3",
                label: "Humid dune slacks (H2190) (s3a3)",
                data: { level: 5 }
              },
              {
                key: "s3a4",
                label: "Dunes with juniper thickets (H2550) (s3a4)",
                data: { level: 5 }
              },
              {
                key: "s3a5",
                label: "Embryonic shifting dunes (H2110) (s3a5)",
                data: { level: 5 }
              },
              {
                key: "s3a6",
                label: "Shifting dunes with marram (H2120) (s3a6)",
                data: { level: 5 }
              },
              {
                key: "s3a7",
                label: "Dune grassland (H2130) (s3a7)",
                data: { level: 5 }
              },
              {
                key: "s3a8",
                label: "Lime-deficient dune heathland with crowberry (H2140) (s3a8)",
                data: { level: 5 }
              },
              {
                key: "s3a9",
                label: "Coastal dune heathland (H2150) (s3a9)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "s3b",
            label: "Coastal vegetated shingle (s3b)",
            data: { level: 4 },
            children: [
              {
                key: "s3b5",
                label: "Perennial vegetation on coastal shingle (H1220) (s3b5)",
                data: { level: 5 }
              },
              {
                key: "s3b6",
                label: "Annual vegetation of drift lines (H1210) (s3b6)",
                data: { level: 5 }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    key: "t",
    label: "Marine inlets and transitional waters (t)",
    data: { level: 2 },
    children: [
      {
        key: "t1",
        label: "Littoral Rock (t1)",
        data: { level: 3 },
        children: [
          {
            key: "t1a",
            label: "Intertidal chalk (t1a)",
            data: { level: 4 }
          },
          {
            key: "t1b",
            label: "Sabellaria alveolata reefs (t1b)",
            data: { level: 4 }
          },
          {
            key: "t1c",
            label: "Intertidal underboulder communities (t1c)",
            data: { level: 4 }
          },
          {
            key: "t1d",
            label: "Estuarine rocky habitats (t1d)",
            data: { level: 4 }
          },
          {
            key: "t1e",
            label: "Splash zone with lichens (t1e)",
            data: { level: 4 }
          },
          {
            key: "t1f",
            label: "Other littoral rock (t1f)",
            data: { level: 4 }
          }
        ]
      },
      {
        key: "t2",
        label: "Littoral Sediment (t2)",
        data: { level: 3 },
        children: [
          {
            key: "t2a",
            label: "Coastal saltmarsh (t2a)",
            data: { level: 4 },
            children: [
              {
                key: "t2a5",
                label: "Glasswort and other annuals colonising mud and sand (H1310) (t2a5)",
                data: { level: 5 }
              },
              {
                key: "t2a6",
                label: "Cord-grass swards (H1320) (t2a6)",
                data: { level: 5 }
              },
              {
                key: "t2a7",
                label: "Atlantic salt meadows (H1330) (t2a7)",
                data: { level: 5 }
              },
              {
                key: "t2a8",
                label: "Mediterranean saltmarsh scrub (H1420) (t2a8)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "t2b",
            label: "Blue mussel beds on sediment (t2b)",
            data: { level: 4 }
          },
          {
            key: "t2c",
            label: "Seagrass beds [Zostera noltei] (t2c)",
            data: { level: 4 }
          },
          {
            key: "t2d",
            label: "Intertidal mudflats (t2d)",
            data: { level: 4 },
            children: [
              {
                key: "t2d5",
                label: "Intertidal mudflats and sandflats (H1140) (t2d5)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "t2e",
            label: "Sheltered muddy gravels (t2e)",
            data: { level: 4 }
          },
          {
            key: "t2f",
            label: "Peat and clay exposures with piddocks (t2f)",
            data: { level: 4 }
          },
          {
            key: "t2g",
            label: "Saline lagoons (t2g)",
            data: { level: 4 },
            children: [
              {
                key: "t2g5",
                label: "Saline lagoons (H1150) (t2g5)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "t2h",
            label: "Beach (t2h)",
            data: { level: 4 }
          }
        ]
      }
    ]
  },
  {
    key: "u",
    label: "Urban (u)",
    data: { level: 2 },
    children: [
      {
        key: "u1",
        label: "Built-up areas and gardens (u1)",
        data: { level: 3 },
        children: [
          {
            key: "u1b",
            label: "Developed land – sealed surface (u1b)",
            data: { level: 4 },
            children: [
              {
                key: "u1b5",
                label: "Buildings (u1b5)",
                data: { level: 5 }
              },
              {
                key: "u1b6",
                label: "Other developed land (u1b6)",
                data: { level: 5 }
              }
            ]
          },
          {
            key: "u1c",
            label: "Artificial unvegetated – unsealed surface (u1c)",
            data: { level: 4 },
          },
          {
            key: "u1d",
            label: "Suburban mosaic of developed and natural surface (u1d)",
            data: { level: 4 },
          },
          {
            key: "u1e",
            label: "Built linear features (u1e)",
            data: { level: 4 },
          },
          {
            key: "u1f",
            label: "Sparsely vegetated urban land (u1f)",
            data: { level: 4 },
          }
        ]
      }
    ]
  },
  {
    key: "w",
    label: "Woodland and forest (w)",
    level: "2",
    children: [
      {
        key: "w1",
        label: "Broadleaved and mixed woodland (w1)",
        level: "3",
        children: [
          {
            key: "w1a",
            label: "Upland oakwood (w1a)",
            level: "4",
            children: [
              {
                key: "w1a5",
                label: "Western acidic oak woodland (H91A0) (w1a5)",
                level: "5"
              }
            ]
          },
          {
            key: "w1b",
            label: "Upland mixed ashwoods (w1b)",
            level: "4",
            children: [
              {
                key: "w1b5",
                label: "Lime-maple woodlands of rocky slopes (H9180) (w1b5)",
                level: "5"
              },
              {
                key: "w1b6",
                label: "Other upland mixed ashwoods (w1b6)",
                level: "5"
              }
            ]
          },
          {
            key: "w1c",
            label: "Lowland beech and yew woodland (w1c)",
            level: "4",
            children: [
              {
                key: "w1c5",
                label: "Beech forests on acid soils (H9120) (w1c5)",
                level: "5"
              },
              {
                key: "w1c6",
                label: "Beech forests on neutral to rich soils (H9130) (w1c6)",
                level: "5"
              },
              {
                key: "w1c7",
                label: "Yew-dominated woodland (H91J0) (w1c7)",
                level: "5"
              },
              {
                key: "w1c8",
                label: "Natural box scrub (H5110) (w1c8)",
                level: "5"
              }
            ]
          },
          {
            key: "w1d",
            label: "Wet woodland (w1d)",
            level: "4",
            children: [
              {
                key: "w1d5",
                label: "Alder woodland on floodplains (H91E0) (w1d5)",
                level: "5"
              },
              {
                key: "w1d6",
                label: "Bog woodland (H91D0) (w1d6)",
                level: "5"
              }
            ]
          },
          {
            key: "w1e",
            label: "Upland birchwoods (w1e)",
            level: "4"
          },
          {
            key: "w1f",
            label: "Lowland mixed deciduous woodland (w1f)",
            level: "4",
            children: [
              {
                key: "w1f5",
                label: "Dry oak-dominated woodland (H9190) (w1f5)",
                level: "5"
              },
              {
                key: "w1f6",
                label: "Oak-hornbeam forests (H9160) (w1f6)",
                level: "5"
              },
              {
                key: "w1f7",
                label: "Other Lowland mixed deciduous woodland (w1f7)",
                level: "5"
              }
            ]
          },
          {
            key: "w1g",
            label: "Other broadleaved woodland (w1g)",
            level: "4"
          },
          {
            key: "w1h",
            label: "Other woodland – mixed (w1h)",
            level: "4",
            children: [
              {
                key: "w1h5",
                label: "Other woodland – mixed – mainly broadleaved (w1h5)",
                level: "5"
              },
              {
                key: "w1h6",
                label: "Other woodland – mixed – mainly conifer (w1h6)",
                level: "5"
              }
            ]
          }
        ]
      },
      {
        key: "w2",
        label: "Coniferous woodland (w2)",
        level: "3",
        children: [
          {
            key: "w2a",
            label: "Native pine woodlands (w2a)",
            level: "4",
            children: [
              {
                key: "w2a5",
                label: "Caledonian forest (H91C0) (w2a5)",
                level: "5"
              }
            ]
          },
          {
            key: "w2b",
            label: "Other Scot's Pine woodland (w2b)",
            level: "4"
          },
          {
            key: "w2c",
            label: "Other coniferous woodland (w2c)",
            level: "4"
          }
        ]
      }
    ]
  }
];



@Injectable({
  providedIn: 'root'
})
export class SymbologyService {

  matchSymbology(key: string) {
    return ukHabSybmology.find(([k]) => key.startsWith(k))?.[1];
  }

  generateSymbology = (type: MapSymbologyId, id: string, distinctiv: number, ccode: string) => {
    const size = 20;
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');

    const config = type === 'standard'
      ? ukHabSybmology.find(([key]) => id.startsWith(key))?.[1]
      : distinctivenessSymbology.find(([key]) => distinctiv === key)?.[1];

    if (!config) {
      console.warn('Missing symbology config for ', id);
      ctx!.fillStyle = '#BBBBBB';
      ctx!.fillRect(0, 0, size, size);
      return ctx!.getImageData(0, 0, canvas.width, canvas.height);
    }

    if (typeof config === 'string') {
      ctx!.fillStyle = config;
      ctx!.fillRect(0, 0, size, size);
      return ctx!.getImageData(0, 0, canvas.width, canvas.height);
    }

    const center = size / 2;
    ctx!.fillStyle = config.backgroundColor ?? '#FFFFFF';
    ctx!.fillRect(0, 0, size, size);
    ctx!.fillStyle = ctx!.strokeStyle = config.color;
    ctx!.beginPath();

    switch (config.type) {
      case 'stripes': {
        const angle = config.angle ?? 0;
        const length = config.dash ? size / 2 : size * Math.sqrt(2);
        ctx!.lineWidth = size / config.lineWidth;
        ctx!.save();

        // if 135deg diagonal
        angle === 135 && ctx!.translate(size, 0);
        // draw main line
        ctx!.rotate((angle) * Math.PI / 180);
        ctx!.moveTo(0, 0); ctx!.lineTo(length, 0);
        ctx!.restore();
        // 45deg 2-side-lines
        if (angle === 45) {
          ctx!.moveTo(-size, 0); ctx!.lineTo(size, 2 * size);
          ctx!.moveTo(0, -size); ctx!.lineTo(2 * size, size);
        }
        // 135deg 2-side-lines
        if (angle === 135) {
          ctx!.moveTo(2 * size, 0); ctx!.lineTo(0, 2 * size);
          ctx!.moveTo(size, -size); ctx!.lineTo(-size, size);
        }
        ctx!.stroke();
        break;
      }
      case 'dots': {
        const radius = size / Math.sqrt(2) / 4;
        // 5 circles
        const offsets = [[0.5, 0.5], [0.5, 1.5], [1.5, 0], [1.5, 1], [1.5, 2]];
        offsets.forEach(([offsetX, offsetY]) => {
          ctx!.beginPath();
          ctx!.arc(offsetX * center, offsetY * center, radius, 0, 2 * Math.PI, false);
          ctx!.fill();
          ctx!.stroke();
        });

        // ONE CIRCLE
        // ctx!.beginPath();
        // ctx!.arc(center, center, radius, 0, 2 * Math.PI, false);
        // ctx!.fill();
        break;
      }
      case 'rhombs': {
        // 4 RHOMBs
        const offsets = [[0.5, 0], [0.5, 1],
        ...config.border ? [[1.5, 0]] : [],
        ...config.border ? [[1.5, 1]] : []
        ];
        offsets.forEach(([offsetX, offsetY]) => {
          ctx!.save();
          ctx!.translate(center * offsetX, center * offsetY);
          ctx!.rotate(45 * Math.PI / 180);
          const rectMethod = config.border ? 'rect' : 'fillRect';
          ctx![rectMethod](0, 0, size / (2 * Math.sqrt(2)), size / (2 * Math.sqrt(2)));
          ctx!.restore();
        });
        ctx!.stroke();

        // ONE RHOMB
        // ctx!.save();
        // ctx!.translate(center, 0);
        // ctx!.rotate(45 * Math.PI/180);
        // ctx!.fillRect(0, 0, size/Math.sqrt(2), size/Math.sqrt(2));
        // ctx!.restore();
        break;
      }
    }

    // Debug square
    // ctx!.beginPath();
    // ctx!.strokeStyle = '#FF0000';
    // ctx!.strokeRect(0, 0, size, size);

    // ctx!.stroke();

    return ctx!.getImageData(0, 0, canvas.width, canvas.height);
  }

  getLineSymbology(code: string): [string, string] {
    if (!code) {
      return ['#000', '#000'];
    }

    const [primary, ...secondary] = code.split(' ');
    for (const [p, c, d] of lineColorMap) {
      if (p === primary) {
        return [c, d];
      }
    }

    // Handling for line of trees
    if (primary.startsWith('w') && secondary.includes('33') || secondary.includes('34')) {
      return ['#339f2c', '#000000'];
    }
    return ['#000', '#000'];
  }
}
